<template>
  <div class="flex flex-col w-full my-2 text-input">
    <p class="px-3 text-lg font-extrabold text-left text-primary">{{label}}</p>
    <div
      class="flex justify-between px-2 mx-2 rounded-lg"
      :class="[
        !valid ? 'border-2 border-red-500' : 'border-2 border-transparent',
        {'bg-background': mode !== 'display'}
      ]"
    >
      <select
        @change="change"
        :name="label"
        :value="value"
        :disabled="mode === 'display'"
        class="`
          flex-grow py-1 font-bold focus:outline-none bg-transparent
          min-w-0
        `"
      >
      <option v-for="item in items" :key="item" :value="item">{{item}}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextInputComponent',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    mode: {
      type: String,
      required: true,
      validate: (val) => ['form', 'edit', 'display'].includes(val),
    },
    valid: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      data: '',
      edit: false,
    };
  },
  methods: {
    change($event) {
      this.$emit('update:value', $event.target.value);
      this.$emit('save', $event.target.value);
    },
  },
};
</script>
