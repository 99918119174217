<template>
  <div class="flex flex-col w-full my-2 text-input">
    <p class="px-3 text-lg font-extrabold text-left text-primary">{{label}}</p>
    <div class="flex flex-col justify-between w-full px-2">
      <textarea
        :ref="label"
        @keyup="change"
        autocomplete="off"
        :name="label"
        v-model="data"
        class="`
          flex-grow px-3 py-1 font-bold rounded-lg focus:outline-none bg-transparent
          min-w-0
        `"
        :class="[
          !isValid ? 'border-2 border-red-500' : '',
          {'bg-background': allowInput}
        ]"
        type="text"
        :disabled="!allowInput"
        :rows="rows"
      />
      <button
        v-if="mode === 'edit' || mode === 'edit2'"
        @click="toggleEdit"
        class="`
          px-3 mt-2 text-md font-semibold opacity-80 rounded-lg
          bg-background focus:outline-none
          flex-shrink-0
        `"
      >
        {{edit ? 'Save' : 'Edit'}}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextareaComponent',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: true,
      validate: (val) => ['form', 'edit', 'display', 'edit2'].includes(val),
    },
    type: {
      type: String,
      required: false,
      default: 'text',
      validator: (val) => ['password', 'email', 'text', 'number'].includes(val),
    },
    valid: {
      type: Boolean,
      required: true,
    },
    rows: {
      type: Number,
      required: false,
      default: 3,
    },
  },
  data() {
    return {
      data: '',
      edit: false,
    };
  },
  computed: {
    allowInput() {
      return this.mode === 'form' || this.edit;
    },
    isValid() {
      if (this.mode === 'edit') {
        return this.data.length > 0;
      }
      return this.valid;
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) this.data = newVal;
    },
  },
  created() {
    this.data = this.value;
  },
  methods: {
    change() {
      if (this.mode === 'form') this.update();
    },
    toggleEdit() {
      if (this.isValid) {
        const oldVal = this.edit;
        this.edit = !this.edit;
        if (oldVal) {
          this.update();
          this.$emit('save');
        } else {
          this.$refs[this.label].focus();
        }
      }
    },
    update() {
      this.$emit('update:value', this.data);
    },
  },
};
</script>
