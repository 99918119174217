<template>
    <div class="flex flex-col items-center justify-start flex-shrink-0">
        <div
            class="flex flex-row items-center justify-between flex-shrink-0 w-72 md:w-80 lg:w-96"
        >
            <chevron-left
            v-if="images.length > 1"
            @click="$emit('update:selected', selected - 1)"
            class="cursor-pointer text-content"
            />
            <div class="flex items-center justify-center flex-grow h-48">
              <div
                    class="relative flex flex-col items-center justify-center h-48 p-2"
              >
                <delete-option
                  v-if="canDelete"
                  @click="$emit('delete-image')"
                  :size="20"
                  class="`
                    absolute top-2 right-2 z-10 font-bold text-red-700 shadow-2lg cursor-pointer
                  `"
                />
                <img
                    v-for="(image, index) in images"
                    v-show="selected === index"
                    :key="`image-${index}`"
                    :src="images[index]"
                    class="object-scale-down max-h-full rounded-lg shadow-lg select-none"
                    alt=""
                >
              </div>
            </div>
            <chevron-right
            v-if="images.length > 1"
            @click="$emit('update:selected', selected + 1)"
            class="cursor-pointer text-content"
            />
        </div>

        <div class="flex flex-row py-2">
            <double-circle
            v-for="(img, index) in images"
            @click="$emit('update:selected', index)"
            :key="`images-${index}`"
            :size="12"
            class="px-1 cursor-pointer"
            :class="[selected === index ? 'text-gray-400' : 'text-gray-800']"
            />
        </div>

    </div>
</template>

<script>
import DoubleCircle from 'vue-material-design-icons/Circle.vue';
import ChevronLeft from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronRight from 'vue-material-design-icons/ChevronRight.vue';
import DeleteOption from 'vue-material-design-icons/Delete.vue';
// ["/img/default-item-images/Moving.jpeg","/img/forgot-password.png"]
export default {
  name: 'CarouselView',
  components: {
    DoubleCircle,
    ChevronLeft,
    ChevronRight,
    DeleteOption,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    selected: {
      type: Number,
      required: true,
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    selected: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;

        const max = this.images.length - 1;
        if (newVal < 0) this.$emit('update:selected', max);
        else if (newVal > max) this.$emit('update:selected', 0);
        else this.$emit('update:selected', newVal);
      },
    },
  },
};
</script>

<style>

</style>
