var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full my-2 text-input"},[_c('p',{staticClass:"px-3 text-lg font-extrabold text-left text-primary"},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"flex justify-between w-full px-2"},[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data),expression:"data"}],ref:_vm.label,staticClass:"`\n        flex-grow px-3 py-1 font-bold rounded-lg focus:outline-none bg-transparent\n        min-w-0\n      `",class:[
        !_vm.isValid ? 'border-2 border-red-500' : 'border-2 border-transparent',
        {'bg-background': _vm.allowInput}
      ],attrs:{"autocomplete":"off","name":_vm.label,"placeholder":_vm.placeholder,"disabled":!_vm.allowInput,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.data)?_vm._i(_vm.data,null)>-1:(_vm.data)},on:{"keyup":_vm.change,"enter":_vm.toggleEdit,"change":function($event){var $$a=_vm.data,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.data=$$a.concat([$$v]))}else{$$i>-1&&(_vm.data=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.data=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data),expression:"data"}],ref:_vm.label,staticClass:"`\n        flex-grow px-3 py-1 font-bold rounded-lg focus:outline-none bg-transparent\n        min-w-0\n      `",class:[
        !_vm.isValid ? 'border-2 border-red-500' : 'border-2 border-transparent',
        {'bg-background': _vm.allowInput}
      ],attrs:{"autocomplete":"off","name":_vm.label,"placeholder":_vm.placeholder,"disabled":!_vm.allowInput,"type":"radio"},domProps:{"checked":_vm._q(_vm.data,null)},on:{"keyup":_vm.change,"enter":_vm.toggleEdit,"change":function($event){_vm.data=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data),expression:"data"}],ref:_vm.label,staticClass:"`\n        flex-grow px-3 py-1 font-bold rounded-lg focus:outline-none bg-transparent\n        min-w-0\n      `",class:[
        !_vm.isValid ? 'border-2 border-red-500' : 'border-2 border-transparent',
        {'bg-background': _vm.allowInput}
      ],attrs:{"autocomplete":"off","name":_vm.label,"placeholder":_vm.placeholder,"disabled":!_vm.allowInput,"type":_vm.type},domProps:{"value":(_vm.data)},on:{"keyup":_vm.change,"enter":_vm.toggleEdit,"input":function($event){if($event.target.composing){ return; }_vm.data=$event.target.value}}}),(_vm.mode === 'edit')?_c('button',{staticClass:"`\n        px-3 py-1 text-lg font-semibold opacity-80 rounded-lg\n        bg-background focus:outline-none\n        flex-shrink-0 ml-2\n        edit-text\n      `",on:{"click":_vm.toggleEdit}},[_vm._v(" "+_vm._s(_vm.edit ? 'Save' : 'Edit')+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }