<template>
    <interior-page-content-container
      nav-selection=""
      @back-click="backNav"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="true"
      :modal-show="modal.show"
      :notificationMessage.sync="notificationMessage"
      :notificationTime="notificationTime"
      :notificationType="notificationType"
    >
      <template #modal>
        <modal-confirmation
          title="Discard?"
          description="All of your data will be discarded"
          text-confirm="Discard"
          text-cancel="Cancel"
          @cancel="modalCancel"
          @confirm="modalConfirm"
        />
      </template>

      <template #title>
        Create Item
      </template>

      <template #content>
        <div class="`
          flex flex-col items-stretch self-center h-full pt-10
          w-5/6 phone:w-3/4 md:w-2/3 lg:w-3/5 max-w-md
          mb-10 pb-10
        `">
          <carousel-view
            :images="images"
            :selected.sync="imageSelected"
            :canDelete="false"
            @delete-image="deleteImage"
          />

          <p class="mb-4 text-sm text-content">
            *Landscape Pictures will display best
          </p>

          <!-- Item Info -->
          <form @submit.prevent="createItem" class="w-full p-3 mb-4 rounded-lg bg-backfill">
            <text-input-component
              :value.sync="item.name"
              :valid="validateInputs ? itemValidity.nameValid : true"
              label="Name"
              mode="form"
            />
            <dropdown-component
              label="Category"
              :value.sync='item.category'
              :items="categories"
              mode="form"
            />
            <textarea-component
              :value.sync="item.description"
              label="Description"
              :valid="true"
              mode="form"
              :rows="4"
            />
            <button class="hidden" />
          </form>

          <!-- Actions -->
          <div class="w-full p-3 mb-4 rounded-lg bg-backfill">
            <button-inline
              v-if="item.images.length < 3"
              @click="takePicture"
              label="Add"
              value="Add Photo"
              mode="edit"
              :disabled="false"
            />
            <button-inline
              v-if="item.images.length > 1"
              @click="deleteImage"
              label="Current Picture"
              value="Delete"
              mode="edit"
              :disabled="false"
            />
            <input
                @change="replaceImage" v-show='false'
                type="file" accept="image/*"
              />
          </div>

          <div v-if="item.category === 'Memory'" class="w-full mb-4 ">
            <p class="ml-5 text-xl font-extrabold text-left text-content">Memory Notes</p>

            <div class="w-full p-3 mb-4 rounded-lg bg-backfill">
              <textarea-component
                v-for="question in memoryQuestions"
                :key="question"
                :value='item.memory_info[question]'
                @update:value="updateMemoryInfo(question, $event)"
                :valid='true'
                :label='question'
                class="pt-5"
                mode="form"
                :rows="3"
              />
            </div>
          </div>

          <button-large
            @click="createItem()"
            color="green"
            class="self-center"
            :disabled="createItemExecuted"
          >
            Create Item
          </button-large>
          <div class="h-10 opacity-0">space...</div>

        </div>
      </template>
    </interior-page-content-container>
</template>

<script>

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import CarouselView from '@/components/shared/general/CarouselView.vue';
import ModalConfirmation from '@/components/shared/inputs/ModalConfirmation.vue';
import TextInputComponent from '@/components/shared/inputs/TextInputComponent.vue';
import DropdownComponent from '@/components/shared/inputs/DropdownComponent.vue';
import ButtonInline from '@/components/shared/Buttons/ButtonInline.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';

import * as memoryQuestions from '@/assets/json/memoryQuestions.json';

import {
  ITEM_POST,
} from '@/store/actions';
import TextareaComponent from '../../components/shared/inputs/TextareaComponent.vue';

export default {
  name: 'ItemCreate',
  components: {
    InteriorPageContentContainer,
    CarouselView,
    ModalConfirmation,
    TextInputComponent,
    DropdownComponent,
    TextareaComponent,
    ButtonInline,
    ButtonLarge,
  },
  data() {
    return {
      categories: ['Moving', 'Sale', 'Storage', 'Junk', 'Donate', 'Memory'],
      item: {
        name: '',
        category: 'Moving',
        description: '',
        memory_info: {},
        images: [],
      },
      itemValidity: {
        nameValid: false,
        categoryValid: true,
      },
      itemUnmodified: {
        name: '',
        category: '',
      },
      modal: {
        show: false,
      },
      validateInputs: false,
      imageSelected: 0,
      edited: false,
      actionComplete: false,
      to: this.defaultBackRoute,
      createItemExecuted: false,
      notificationMessage: '',
      notificationTime: 5000,
      notificationType: 'alert',
    };
  },
  computed: {
    images: {
      get() {
        return this.item.images.length > 0
          ? this.item.images.map((image) => this.imageUrl(image))
          : [`/img/default-item-images/${this.item.category}.jpeg`];
      },
      set(newVal) {
        this.item.images = newVal;
      },
    },
    valid() {
      return this.itemValidity.nameValid && this.itemValidity.categoryValid;
    },
    defaultBackRoute() {
      return {
        name: 'Room',
        params: {
          project_id: this.$route.params.project_id,
          room_id: this.$route.params.room_id,
        },
      };
    },
    memoryQuestions() {
      return memoryQuestions.memoryQuestions;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.edited && !this.actionComplete) {
      this.to = to;
      this.modal.show = true;
    } else next();
  },
  watch: {
    item: {
      handler(newVal) {
        this.itemValidity.nameValid = newVal.name.length > 0;
        this.itemValidity.categoryValid = newVal.category.length > 0;

        if (this.item.name !== this.itemUnmodified.name) this.edited = true;
        else if (this.item.category !== this.itemUnmodified.category) this.edited = true;
        else this.edited = false;
      },
      deep: true,
    },
  },
  created() {
    window.addEventListener('keyup', this.navigateImages);
    this.memoryQuestions.forEach((question) => {
      this.item.memory_info[question] = '';
    });
  },
  destroyed() {
    window.removeEventListener('keyup', this.navigateImages);
  },
  methods: {
    imageUrl(obj) {
      return typeof obj === typeof '' ? obj : URL.createObjectURL(obj);
    },
    async getDefaultImageFile() {
      return fetch(this.images[0])
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then((blob) => {
          const file = new File([blob], `${this.item.category}.jpeg`);
          return file;
        });
    },
    async createItem() {
      this.validateInputs = true;

      if (!this.valid) return;
      this.createItemExecuted = true;

      // Added the three pictures
      let pictures = {};
      if (this.item.images.length === 0) {
        pictures = { picture: await this.getDefaultImageFile() };
      }

      if (this.item.images[0]) {
        pictures = {
          ...pictures,
          picture: this.item.images[0],
        };
      }
      if (this.item.images[1]) {
        pictures = {
          ...pictures,
          picture_2: this.item.images[1],
        };
      }
      if (this.item.images[2]) {
        pictures = {
          ...pictures,
          picture_3: this.item.images[2],
        };
      }

      this.edited = false;
      this.$store.dispatch(ITEM_POST, {
        item: {
          name: this.item.name,
          category: this.item.category,
          description: this.item.description.length > 0 ? this.item.description : ' ',
          memory_info: this.item.memory_info,
          ...pictures,
        },
        projectId: this.$route.params.project_id,
        roomId: this.$route.params.room_id,
      })
        .then((newItem) => {
          this.actionComplete = true;
          this.$router.push({
            name: 'Item',
            params: {
              project_id: this.$route.params.project_id,
              room_id: this.$route.params.room_id,
              item_id: newItem.id,
            },
          });
        })
        .catch((error) => {
          this.notificationMessage = error.response.data;
          this.createItemExecuted = false;
        });
    },
    modalConfirm() {
      this.actionComplete = true;
      this.$router.push(this.to);
    },
    modalCancel() {
      this.modal.show = false;
      this.to = this.defaultBackRoute;
    },
    backNav() {
      this.$router.push(this.defaultBackRoute);
    },
    navigateImages(event) {
      if (event.key === 'ArrowRight') this.imageSelected += 1;
      if (event.key === 'ArrowLeft') this.imageSelected -= 1;
    },
    takePicture() {
      document.querySelector('input[type="file"]').click();
    },
    replaceImage(event) {
      this.item.images = [
        ...this.item.images,
        ...event.target.files,
      ];
    },
    deleteImage() {
      this.item.images.pop();
      if (this.imageSelected > 0) this.imageSelected -= 1;
    },
    updateMemoryInfo(key, value) {
      this.item.memory_info[key] = value;
    },
  },

};
</script>
