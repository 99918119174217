<template>
  <div class="flex flex-col w-full my-2 text-input">
    <div class="flex items-center justify-between w-full">
      <p class="px-3 text-lg font-extrabold text-left text-primary">{{label}}</p>
      <button
        v-if="mode === 'edit'"
        :name="label"
        @click="$emit('click')"
        class="`
          px-3 py-1 mx-2 text-lg font-semibold opacity-80 rounded-lg
          bg-background focus:outline-none
        `"
        :class="{'animate-pulse border-2 rounded-lg border-yellow-300':pulse}"
      >
        {{ value }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonInline',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: false,
      default: 'edit',
      validate: (val) => ['edit', 'display'].includes(val),
    },
    pulse: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
};
</script>
