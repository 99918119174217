<template>
  <div class="flex flex-col w-full my-2 text-input">
    <p class="px-3 text-lg font-extrabold text-left text-primary">{{label}}</p>
    <div class="flex justify-between w-full px-2">
      <input
        :ref="label"
        @keyup="change"
        @enter="toggleEdit"
        autocomplete="off"
        :name="label"
        :placeholder="placeholder"
        v-model="data"
        class="`
          flex-grow px-3 py-1 font-bold rounded-lg focus:outline-none bg-transparent
          min-w-0
        `"
        :class="[
          !isValid ? 'border-2 border-red-500' : 'border-2 border-transparent',
          {'bg-background': allowInput}
        ]"
        :type="type"
        :disabled="!allowInput"
      >
      <button
        v-if="mode === 'edit'"
        @click="toggleEdit"
        class="`
          px-3 py-1 text-lg font-semibold opacity-80 rounded-lg
          bg-background focus:outline-none
          flex-shrink-0 ml-2
          edit-text
        `"
      >
        {{edit ? 'Save' : 'Edit'}}
      </button>
    </div>
  </div>
</template>

<script>
import { postalCodeValidation } from '@/utils/validators';

export default {
  name: 'TextInputComponent',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
      default: '',
      validate: (val) => {
        console.log(val);
        return val !== undefined;
      },
    },
    mode: {
      type: String,
      required: true,
      validate: (val) => ['form', 'edit', 'display'].includes(val),
    },
    type: {
      type: String,
      required: false,
      default: 'text',
      validator: (val) => ['password', 'email', 'text', 'number', 'postalCode'].includes(val),
    },
    valid: {
      type: Boolean,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      data: '',
      edit: false,
    };
  },
  computed: {
    allowInput() {
      return this.mode === 'form' || this.edit;
    },
    isValid() {
      if (this.mode === 'edit') {
        if (this.type === 'postalCode') {
          return postalCodeValidation(this.data);
        }
        return this.data.length > 0;
      }

      return this.valid;
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) this.data = newVal;
    },
  },
  created() {
    this.data = this.value;
  },
  methods: {
    change($event) {
      if (this.type === 'postalCode') this.data = this.data.toUpperCase();
      if ($event.which === 13 || $event.keyCode === 13) this.toggleEdit();
      if (this.mode === 'form') this.update();
    },
    toggleEdit() {
      if ((!this.edit && !this.isValid) || this.isValid) {
        const oldVal = this.edit;
        this.edit = !this.edit;
        if (oldVal) {
          this.update();
          this.$emit('save');
        } else {
          this.$refs[this.label].focus();
        }
      }
    },
    update() {
      this.$emit('update:value', this.data);
    },
  },
};
</script>
